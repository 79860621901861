
html,body,#root,.layout,.app{
    height: 100%;
    box-sizing: border-box;
}
.layout_Sider{
    border-radius: 0px 20px 20px 0px;
    overflow: hidden;
}
.logo{
    background: #001529;
    height: 64px;
    text-align: center;
    padding: 20px 10px;
    margin-bottom: 20px;
    img{
        width: 80%;
        cursor: pointer;
    }
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
    border-radius: 20px 0px 0px 20px;
}
.head_abt{
    width: 72px;
    height: 33px;
    line-height: 33px;
    background: #40a9ff;
    border-radius: 17px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    border: none;
    margin-left: 5px;
    position: relative;
    top: 3px;
    outline: none;
    cursor: pointer;
}
.text-right{
    text-align: right;
}
.ant-layout-content{
    height: 100%;
    padding: 20px 0 0 20px;
    overflow: hidden;
    .main_box{
        height: 100%;
        overflow-y:auto ;
        background-color: #fff;
        border-top-left-radius: 20px;
        padding: 24px 0  24px 24px;
    }
}
.title_top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .iocn{
        position: relative;
        top: 2px;
    }
    .clear{
        font-size: 14px;
        font-family: "微软雅黑";
        font-weight: normal;
        padding-right: 35px;
        color: #40a9ff;
        cursor: pointer;
    }
    span{
        font-size: 22px;
        font-family: YouSheBiaoTiHei;
        font-weight: 400;
        color: #333333;
    }
    .color{
        width: 133px;
        height: 7px;
        // background: #0972F4;
        background-image: url('../assets/images/color.png');
    }
}
.home{
    .home_list{
        width: 95%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
        margin-top: 40px;
        .list{
            width: 49%;
            height: 350px;
            background: #FFFFFF;
            border: 1px solid #E3E3E3;
            // box-shadow: 0px 11px 42px 4px rgba(175, 190, 212, 0.43);
            border-radius: 20px;
            margin-bottom: 20px;
            padding: 20px 30px;
            position: relative;
            .list_title{
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #333;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                
                span{
                    font-size: 14px;
                    color: #888;
                    font-weight: normal;
                    cursor: pointer;
                }
                img{
                    margin-right: 5px;
                }
            }
            .position{
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                right: -50px;
                z-index: 100;
            }
            .list_ul{
                .li{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 50px;
                    border-bottom: 1px solid #F1F1F1;
                    cursor: pointer;
                    .name{
                        width: 80%;
                        white-space:nowrap;
                        overflow:hidden;
                        text-overflow:ellipsis;
                    }
                    .time{
                        color: #888;
                    }
                }
                .li:hover{
                    color:#40a9ff;
                }
            }
            .list_foot{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px 0;
                span{
                    width: 6px;
                    height: 6px;
                    background: #CBCFE1;
                    border-radius: 50%;
                    margin: 0 3px;
                }
            }
        }
        .list:hover{
            box-shadow: 0px 11px 42px 4px rgba(175, 190, 212, 0.43);
        }
        .list:nth-child(3),.list:nth-child(4){
            margin-bottom: 0;
        }
    }
}
.details{
    // padding-right: 24px;
    .details_title{
        margin-top: 45px;
        padding-bottom: 20px;
        border-bottom: 1px solid #F0F2F5;
        display: flex;
        // align-items: center;
        justify-content: space-between;
        padding-right: 20px;
        .name{
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #222222;
        }
        .time{
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
            margin-top: 15px;
        }
    }
    .details_body{
        padding: 20px 20px 20px 0;
        font-size: 18px;
        // height: 43vh;
        // overflow: auto;
    }
    .attachment{
        a{
            display: block;
            margin-bottom: 5px;
        }
    }
    .details_foot{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;
        
        .li{
            width: 45%;
            height: 49px;
            background: #F0F2F5;
            border: 1px solid #E0E1E4;
            line-height: 49px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            padding: 0 20px;
            cursor: pointer;
        }
        .li:hover{
            color: #fff;
            background-color: #40a9ff;
        }
    }
}
.gloal_list{
    padding-right: 24px;
    .gloal_list_li{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border-bottom: 1px solid #EEEEEE;
        background-image: url('../assets/images/jiaobiao.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        margin-bottom: 10px;
        cursor: pointer;
    }
    .title{
        .name{
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            .ant-tag{
                margin-left: 5px;
            }
        }

        
    }
    .time{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #555555;
        // margin-top: 15px;
        margin-right:10px;
    }
    .readNum{
        color: #40a9ff;
        
    }
    .num{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #555555;
    }
}
.list_body{
    margin-top: 25px;
    // height: 55vh;
    // overflow: auto;
}
.pagination{
    text-align: center;
    margin-top: 50px;
}
.ant-dropdown-link{
    cursor: pointer;
}
.head_menu{
    background-color: #FFFFFF;
    padding: 0 5px;
    box-shadow:  -1px 1px 10px 0 #ccc;
    .li{
        text-align: center;
        border-bottom: 1px solid #eee;
        padding: 5px 0;
        cursor: pointer;
    }
    .li:last-child{
        border-bottom: none;
    }
}
.quit{
    cursor: pointer;
    margin-left: 10px;
}
.login{
    height: 100%;
    background-image: url(".././/assets/images/login.png");
    background-size: 50%;
    background-repeat: no-repeat;
    .login_main{
        // width: 1200px;
        // margin: auto;
        padding: 40px;
        height: 100%;
        display:flex;
        align-items: center;
        justify-content: center;
        .ant-row{
            width: 100%;
        }
        .login_left{
            display: flex;
            align-items: flex-end;
            height: 100%;
        }
        .login_right{
            display: flex;
            flex-flow: column;
            align-items: center;
            .ant-form-large .ant-form-item-label > label{
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #747A8B;
            }
            .ant-input-lg{
                height: 50px;
                background: none;
            }
            .ant-input-affix-wrapper-lg{
                height: 50px;
                // padding: 11.5px 11px;
                font-size: 16px;
                padding: 0 11px;
            }
            .ant-form-item{
                
                .ant-input-suffix{
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #9DA2BC;
                    cursor: pointer;
                }
            }
        }
        .login_title{
            width: 400px;
            .h1{
                font-size: 47px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #222222;
            }
            .h2{
                font-size: 27px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #222222;
                margin-bottom: 40px;
            }
        }
        .ant-form{
            width: 400px;
        }
        .login-form-button {
            width: 100%;
            height: 55px;
            margin-top: 10px;
        }
    }
}